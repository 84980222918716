import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import AppDropDownListOwnerType from "@/components/Inputs/AppDropDownListOwnerType.vue";
export default Vue.extend({
    components: {
        "app-dropdownlist-owner-type": AppDropDownListOwnerType
    },
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("attachmentTypes.edit.title"),
            id: this.$route.params.id,
            attachmentType: {},
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 100]
                    },
                    description: {
                        maxLength: 255
                    },
                    ownerType: {
                        required: true,
                    }
                }
            }
        };
    },
    methods: {
        edit() {
            return this.$store.dispatch("attachmentType/edit", {
                id: this.id,
                attachmentType: this.attachmentType
            });
        },
        editSuccess() {
            this.$toast.showSuccessToast(this.$t("attachmentTypes.edit.toastSuccessTitle"), this.$t("attachmentTypes.edit.toastSuccessContent", {
                name: this.attachmentType.name
            }));
            return this.$router.push("/attachments/types");
        },
        cancel() {
            return this.$router.push("/attachments/types");
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.attachmentType = await this.$store.dispatch("attachmentType/get", {
                id: this.id
            });
            this.title += ` ${this.attachmentType.name}`;
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/attachments/types");
        }
    }
});
